body {
  margin: 0;
  background-color: rgba(247, 249, 252, 1) !important;
}

.highcharts-bubble-legend-connectors {
  display: none;
}

.highcharts-bubble-legend-labels {
  display: none;
}

.highcharts-tooltip-container {
  z-index: 999999 !important;
}
.highcharts-custom-text{
  width: 100%;
}